// Generated by Framer (999bb5e)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-ECFxK"

const variantClassNames = {bZeGtkTIQ: "framer-v-zkp1ba"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "bZeGtkTIQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zkp1ba", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"bZeGtkTIQ"} ref={ref ?? ref1} style={{backgroundColor: "rgb(12, 31, 240)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, boxShadow: "inset 0px 0px 12px 3px rgba(255, 255, 255, 0.36), 0px 3px 16px 0px rgba(12, 31, 240, 0.35)", ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItcmVndWxhcg==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "21.6px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>WATCH FREE DEMO</motion.p></React.Fragment>} className={"framer-smsir6"} fonts={["GF;Inter-regular"]} layoutDependency={layoutDependency} layoutId={"yNI34jhzw"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ECFxK.framer-1w8frye, .framer-ECFxK .framer-1w8frye { display: block; }", ".framer-ECFxK.framer-zkp1ba { height: 54px; overflow: hidden; position: relative; width: 225px; will-change: var(--framer-will-change-override, transform); }", ".framer-ECFxK .framer-smsir6 { flex: none; height: auto; left: 32px; pointer-events: auto; position: absolute; top: 16px; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 54
 * @framerIntrinsicWidth 225
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameratgpHyYVP: React.ComponentType<Props> = withCSS(Component, css, "framer-ECFxK") as typeof Component;
export default FrameratgpHyYVP;

FrameratgpHyYVP.displayName = "button";

FrameratgpHyYVP.defaultProps = {height: 54, width: 225};

addFonts(FrameratgpHyYVP, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v18/UcCO3FwrK3iLTeHuS_nVMrMxCp50SjIw2boKoduKmMEVuLyfMZ1rib2Bg-4.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})